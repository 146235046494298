@import '../../../styles/settings.scss';

.about {

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
}

.about_info {
  
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.about_infoLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
}

.about_proileImg {
  display: block;
  border-radius: 20px;
  box-shadow: -2px 3px 9px -2px $title-color;
}

.about_slogan {
  padding-top: 15px;
  text-align: center;
  line-height: 1.3em;

}

.about_desc {
  text-align: center;
}

.about_includedIn {
  padding-top: 3em;
}

.about_includedCard {
  width: 100%;

  & * {
    font-size: 15px;
  }

  & img {
    max-width: 150px;
    height: 80px;
  }
}

@media (min-width: 479.98px) {
  .about_includedCard {
    & * {
      font-size: 14px;
    }
    & img {
      max-width: 120px;
      height: 90px;
    }
  }


}

@media (min-width: 575.98px) {
  .about_infoLeft {
    max-width: 35%;
    min-width: 200px;
  }

  .about_includedIn {
    & * {
      font-size: 15px;
    }
  }

  .about_desc {
    text-align: left;
  }

  .about_info {
    flex-direction: row;
  }
}

@media (min-width: 767.98px) {

  .about_infoLeft {
    max-width: 50%;
  }

  .about_proileImg {
    max-width: 270px;
  }

  .about_desc {
    width: 100%;

  }

  .about_includedCard {
    & * {
      font-size: 15px;
    }
    & img {
      height: 80px;
    }
  }

  .about_includedIn {
    gap: 20px;
    flex-wrap: nowrap;
  }
}


@media (min-width: 991.98px) {
  .about_includedCard {
    & * {
      font-size: 16px;
    }
  }
}