@import 'settings.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
  font-size: inherit;
}

body,
html {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  font-size: 14px
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a {
  text-decoration: none;
  font: inherit;
  color: inherit;
}

img {
  max-width: 100%;
  display: block;
}

button {
  cursor: pointer;
}

input,
button,
textarea,
select {
  font: inherit;
  line-height: inherit;
  margin: 0;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}
body {
  font-family: 'Montserrat';
  font-weight: 400;
  color: $text-color;
}

p {
  line-height: 1.6em;
}

html {
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $title-color;
}
*, *:before, *:after {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  word-break: break-word;
}