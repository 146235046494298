.itemsCount5,
.itemsCount6,
.itemsCount7,
.itemsCount8,
.itemsCountMore {
    & * {
        max-height: 450px;
    }
}

.itemsCount1 {
    display: flex !important;

    & * {
        min-height: 400px;
        max-height: 70vh !important;
        height: 100%;
        width: auto !important;
        margin: 0 auto;
        object-fit: contain;

    }

}

.itemsCount2 {
    grid-template-columns: repeat(2, 1fr);

    & * {
        width: 100%;
    }
}

.itemsCount3 {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: max-content;

    &>*:nth-child(1) {
        grid-area: 1/1/3/3;

        & * {
            max-height: 710px;
            height: 100%;
        }
    }

    & * {
        max-height: 350px;
    }

}

.itemsCount4 {
    grid-template-columns: 1fr 1fr;

    & * {
        max-height: 500px;
    }
}

.itemsCount5 {
    &>*:nth-child(1) {
        grid-area: 1 / 1 / 3 / 3;
    }

    &>*:nth-child(2) {
        grid-area: 1 / 3 / 3 / 4;
    }


}

.itemsCount6 {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &>*:nth-child(1) {
        grid-area: 1 / 1 / 3 / 3;
    }

    &>*:nth-child(2) {
        grid-area: 1 / 3 / 3 / 5;
    }
}

.itemsCount7 {
    grid-template-columns: repeat(5, 1fr);

    &>*:nth-child(1) {
        grid-area: 1 / 1/ 3 / 4;
    }

    &>*:nth-child(2) {
        grid-area: 1 / 4/ 3 / 6;
    }
}

.itemsCount8 {
    grid-template-columns: repeat(6, 1fr);

    &>*:nth-child(1) {
        grid-area: 1 / 1/ 3 / 4;
    }

    &>*:nth-child(2) {
        grid-area: 1 / 4/ 3 / 7;
    }
}

.itemsCount9 {
    & * {
        max-height: 350px;
    }
}

.itemsCount9,
.itemsCount5 {
    grid-template-columns: 1fr 1fr 1fr;

}

.itemsCountMore {
    grid-template-columns: repeat(8, 1fr);

    &>*:nth-child(1) {
        grid-area: 1 / 1/ 1 /5;
    }

    &>*:nth-child(2) {
        grid-area: 1 /5/ 1 / 9;

    }

    & * {
        max-height: 450px;
    }
}
