@import '../../styles/settings.scss';
.button{
    background-color: $blue-color-600;
    color: $primary-color;
    padding: 10px 14px;
    border: none;
    overflow: hidden;
    font-weight: 600;
    border-radius: 10px;
    position: relative;
    &:hover {
        & .button_before{
            transform: translateX(-150%);
        }

        & .button_after{
            transform: translateX(0%);
        }
    }
}
.button_before, .button_after{
    position: absolute;
    object-fit: contain;
    top: 0;
    height: 100%;
    width: 20px;
    display: none;
    transition: transform .3s ease-in-out;
}
.button_before{
    left: 5px;
}
.button_after{
    right: 5px;
    transform: translateX(150%);
}

@media (any-hover: hover){
    .button{
        padding: 10px 30px;

    }
    .button_before, .button_after{
        display: block;

    }
}
