@import '../../../../styles/settings.scss';

.blog {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.blog_header {
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    flex-direction:column;
    gap: 15px;
}


.blog_buttons {
    display: flex;
    gap: 20px;
}
.blog_text {
    padding-bottom: 15px;
    text-align: justify;
}

.blog_dateTime {
    justify-content: center;
    color: $title-color;
    font-size: 20px;
}

.blog_inner .blog_grid {
    & > * {
        width: 100%;
    }
}

@media (min-width: 575.98px) {
    .blog_header {
        flex-direction: row;
    }
    .blog_dateTime {
        font-size: 24px;
    }
    .blog_text {
        padding-bottom: 20px;
    }
}

@media (min-width: 767.98px) {
    .blog_inner {
        display: flex;
        gap: 30px;
        justify-content: flex-end;
        flex-direction: row-reverse;
    
        & .blog_text {
            padding-bottom: 0;
            max-width: 60%;
            text-align: justify;
        }

    }
}