@import '../../../styles/settings.scss';

.text_paragraph{
    padding-top: 5px;
    display: block;
    &:first-child{
        padding-top: 0px;  
    }
}
.text_tagBox {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px
}

.text_tag {
    padding: 8px 12px;
    border-radius: 2em;
    color: $primary-color;
    font-weight: 600;
    word-break: break-all;
    text-align: center;
    &:nth-child(1n) {
        background-image: linear-gradient(to right, $red-color-600, $red-color-400, $red-color-500);
    }

    &:nth-child(2n) {
        background-image: linear-gradient(to right, $blue-color-400, $blue-color-500);
    }

    &:nth-child(3n) {
        background-image: linear-gradient(to right, $green-color-500, $green-color-600);
    }

}
