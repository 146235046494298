@import '../../../../styles/settings.scss';

.blogCard {
    background-color: $primary-color;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &::after {
        position: absolute;
        transition: all .3s ease-in-out;
        content: 'Нажмите для просмотра';
        background-color: $primary-color;
        border-radius: 0 0 10px 0;
        font-weight: 500;
        padding: 5px;
        top: 0px;
        left: 0px;
        color: $shadow-color;
    }
}

.blogCard_img {
    transition: transform .5s ease-in-out;
    width: 100%;
    height: 100%;
    max-height: 480px;
    object-fit: cover;
    will-change: transform;
}

.blogCard_desc {
    transition: all .3s ease-in-out;
    padding-top: 5px;
    position: absolute;

    background-color: $primary-color;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;
    max-height: 80%;
    display: flex;
    flex-direction: column;
}

.blogCard_title {
    flex-grow: 1;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 500;
    padding-top: 5px;
    display: none;

}
.blogCard_tags{
    display: none !important;
}
.blogCard_date {
    color: $text-color;
    font-weight: 600;
    font-size: 18px;
    justify-content: center;
}

@media (any-hover: hover) {
    .blogCard {
        &:hover .blogCard_desc {
            transform: translateY(0);
            opacity: 1;
        }

        &:hover .blogCard_img {
            transform: scale(1.1);
        }

        &:hover::after {

            opacity: 1;
            transform: translateY(0%);
        }
    }

    .blogCard {
        & .blogCard_desc {
            transform: translateY(100%);
            opacity: 0;
        }
        & .blogCard_title {
            display: -webkit-box;
        }
        &::after {
            opacity: 0;
            transform: translateY(-100%);
        }
        & .blogCard_tags{
            display: flex  !important;
        }
        & .blogCard_date {
            font-size: 14px;
            justify-content: flex-start;
        }
    }

}