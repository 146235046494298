.shortBlog{
}
.shortBlog_events{
  padding-top: 3em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  row-gap: 35px;
  padding-bottom: 20px;
}
.shortBlog_event {
  width: 100%;
  height: 100%;
}

@media (min-width: 479.98px) {}

@media (min-width: 575.98px) {
}

@media (min-width: 767.98px) {
  .shortBlog_events{
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
  }
}


@media (min-width: 991.98px) {
  .shortBlog_events{
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    row-gap: 40px;
  }
}