$text-color: #5a5a5a;
$title-color: #363838;
$primary-color: #FFF;
$neutral-color: #F5F5F5;
$shadow-color: #000;

$red-color-600: #f52f42;
$red-color-500: #e14e53;
$red-color-400: #f15e64;

$green-color-600: #0ba360;
$green-color-500: #8BB875;

$blue-color-600:#157FC4;
$blue-color-500:#764ba2;
$blue-color-400:#667eea;

$container-width: 1100px;
