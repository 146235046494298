@import '../../../../styles/settings.scss';

.contactLink {
    display: block;
    position: relative;
    padding-left: 27px;
    font-weight: 500;
    color: $title-color;
    white-space: nowrap;
    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '';
        height: 20px;
        width: 20px;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &___phone {
        &::before {
            background-image: url('../../../../assets/icons/phone.svg');
        }
    }
    &___email {
        &::before {
            background-image: url('../../../../assets/icons/email.svg');
        }
    }
    &___vk {
        &::before {
            background-image: url('../../../../assets/icons/VK.svg');
    
        }
    }
}
