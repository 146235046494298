@import 'reset.scss';
@import 'fonts.scss';
@import 'settings.scss';
.container {
  max-width: $container-width;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}


@media (min-width: 575.98px) {
  body{
    font-size: 15px;
  }
}


@media (min-width: 991.98px) {
  body{
    font-size: 16px;
  }
}