@import '../../styles/settings.scss';

.inputBox {
    position: relative;
    padding: 11px 0 0;
    width: 100%;
    background-color: $primary-color;
    
    &___isError {
        & .inputBox_input {
            border-bottom: 2px solid $red-color-600 !important;

        }

        & .inputBox_label {
            color: $red-color-600 !important;

        }
    }
}

.inputBox_input {
    width: 100%;
    font-family: inherit;
    border: 0;
    border-bottom: 2px solid $text-color;
    outline: 0;
    font-size: inherit;
    padding: 7px 0;
    background: transparent;
    transition: border-color, color 0.2s;
    font-weight: 500;
    color: $text-color;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown~.inputBox_label {
        font-size: inherit;
        cursor: text;
        top: 20px;
    }

}

.inputBox_label {
    position: absolute;
    white-space:nowrap;
    word-wrap: break-word;
    width: 100%;
    overflow: hidden;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 13px;
    font-weight: 600;
    color: $text-color;
    background-color: inherit;
    pointer-events: none;
}


.inputBox_input:focus {
    ~.inputBox_label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 13px;
        color: $text-color;
        font-weight: 600;
    }

    color: $text-color;
    border-image: linear-gradient(to right, $blue-color-600, $blue-color-500);
    border-image-slice: 1;
}

.inputBox_input {

    &:required,
    &:invalid {
        box-shadow: none;
    }
}
.inputBox_error{
    font-size: 13px;
    margin-top: 4px;
    color: $red-color-600;
}

.inputBox_input:-webkit-autofill,
.inputBox_input:-webkit-autofill:hover,
.inputBox_input:-webkit-autofill:focus {
    border-image: linear-gradient(to right, $blue-color-600, $blue-color-500);
    border-image-slice: 1;
    -webkit-text-fill-color: $text-color;
    -webkit-box-shadow: 0 0 0px 1000px none inset;
    transition: background-color 5000s ease-in-out 0s;

    appearance: menulist-button;
    background-image: none !important;
    color: $title-color !important;
}


.inputBox_input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0}
.inputBox_input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button{height:auto}
.inputBox_input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box}
.inputBox_input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none}
.inputBox_input[type=password]::-ms-reveal,
.inputBox_input[type=password]::-ms-clear
{
    display: none;
}

.inputBox_input::-webkit-outer-spin-button,
.inputBox_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}