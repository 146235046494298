@import '../../../../styles/settings.scss';

.blogList {
    padding-top: 100px;
    background-color: $neutral-color;

}
.blogList_loader{
    padding-top: 180px;
}
.blogList_list {
    padding-top: 40px;
    height: 100%;
    position: relative;
}

.blogList_blog {
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 0;
    }
}
.blogList_empty{
    font-size: 18px;
    color: $text-color;
}