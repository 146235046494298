@import '../../styles/settings.scss';

.section {
    overflow: hidden;
    padding: 1.6em 0;
    background-color: $primary-color;

    &:nth-child(odd) {
        background-color: $neutral-color;
    }
}

.section___fullSize,.section___firstSection {
    min-height: 100vh;
    min-height: 100dvh;
}
.section___firstSection{
    padding-top: 100px;
}