@import '../../styles/settings.scss';

.title {
  font-weight: 700;
  position: relative;
  letter-spacing: 0.03em;
  line-height: 1.4em;

  &::after {
    content: '';
    position: absolute;
    bottom: -.7em;
    width: 100px;
    height: 3px;
    background-color: $text-color;
    opacity: 0;
  }

  &___posCetner {
    text-align: center;

  }

  &___lineCenter {
    &::after {
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;

    }
  }

  &___lineLeft {
    &::after {
      left: 0;
      opacity: 1;
    }
  }

  &___sectionTitle {
    font-size: 20px;

  }

  &___primaryTitle {
    font-size: 22px;
  }
}


@media (min-width: 479.98px) {


  .title___sectionTitle,
  .title___primaryTitle {
    font-size: 24px;

    &::after {
      bottom: -.6em;
    }

  }

  .title___primaryTitle {
    font-size: 26px;
  }
}

@media (min-width: 575.98px) {

  .title___sectionTitle,
  .title___primaryTitle {
    font-size: 28px;
    line-height: 1.5em;
    letter-spacing: 0.05em;

    &::after {
      bottom: -.5em;
      height: 4px;

    }
  }

  .title___primaryTitle {
    font-size: 30px;
  }
}

@media (min-width: 767.98px) {

  .title___sectionTitle,
  .title___primaryTitle {
    font-size: 32px;
    line-height: 1.6em;

    &::after {
      bottom: -.4em;

    }
  }

  .title___primaryTitle {
    font-size: 34px;
  }
}

@keyframes animate-down {
  0% {
    transform: translateY(-100%);

  }

  100% {
    transform: translateY(0);

  }
}