@import '../../../../styles/settings.scss';

.grid {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.grid_imageBox {
    overflow: hidden;
}

.grid_image {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 600px;
    cursor: pointer;
}


.grid_video {
    position: relative;
    display: block;
    background-color: $primary-color;

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        background-image: url('../../../../assets/icons/triangle.svg');
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
        background-size: contain;
        transform: translate(-45%, -50%);
        background-position: center;
    }


    &::before {

        padding: 25px;
        border-radius: 50%;
        background-color: rgba($shadow-color, .5);
    }

    &:hover {
        &::before {
            background-color: rgba($shadow-color, .6);

        }
    }
}

.grid_modalImage {
    position: relative;
    height: 100%;
    min-width: 180px;
    object-fit: cover;
}

.grid_modal {
    position: relative;
    z-index: 1;
    max-height: 100%;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

}

.grid_closeBtn {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background-color: $red-color-600;
    border: none;
    border-radius: 15px;
    padding: 10px 15px;
    color: $primary-color;
    font-size: 16px;
    font-weight: 600;
    box-sizing: content-box;
    transition: transform .3s ease-in-out;
    will-change: transform;

    &:hover {
        transform: scale(1.1);
    }
}

@media (min-width: 479.98px) {
    .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &>* {
            width: calc(50% - 4px);
        }
    }

}

@media (min-width: 767.98px) {
    .grid {
        display: grid;
        &>* {
            width: auto;
        }
    }
}