@import '../../styles/settings.scss';
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: $blue-color-600 8px solid;
        border-radius: 50%;
        animation: Loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $blue-color-600 transparent transparent transparent;

        & div:nth-child(1) {
            animation-delay: -0.45s;
        }

        & div:nth-child(2) {
            animation-delay: -0.3s;
        }

        & div:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes Loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}