@import '../../styles/settings.scss';

.multipleInput {
    border: 2px dashed $text-color;
    border-radius: 4px;
    &___isError{
        border: 2px solid $red-color-600;
        & .multipleInput_input{
            color: $red-color-600;
        }
    }
    &___isHover{
        border: 2px solid $blue-color-600;
    }
}

.multipleInput_input {
    padding: 16px;
    font: inherit;
    text-align: center;
    height: 100%;
    width: 100%;
    display: block;
    cursor: pointer;
    display: block;
}


.multipleInput_fileList {
    list-style: inside;
    text-align: center;
}

.multipleInput_inputHint{
    display: block;
    padding-top: 7px;
    color: $title-color;
    font-size: 14px;
    font-weight: 400;
}