@import '../../styles/settings.scss';

.eventCard {
    padding-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $primary-color;
    border: 1px solid $neutral-color;
    border-radius: 14px;
    box-shadow: 0px 2px 4px rgba($shadow-color, 0.1);
    &___fullSize {
        &>.eventCard_desc {
            display: block;
            overflow-y: auto;
        }
    }
}
.eventCard_header {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 20px;
    padding-bottom: 0px;

}

.eventCard_headerTitle {
    transition: color .2s ease-in-out;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.eventCard_headerAbout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 6px;
}

.eventCard_eventType {
    font-size: 16px;
    font-weight: 500;
    color: $text-color;
    margin-right: 10px;
}

.eventCard_dates {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 2px;
}

.eventCard_date {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.eventCard_dateLabel {
    font-size: 13px;
    color: $text-color;
    margin-right: 5px;
}

.eventCard_address {
    font-size: 15px;
    font-weight: 500;
    color: $title-color;
    font-style: normal;
    margin-top: 10px;
}

.eventCard_desc {
    overflow: hidden;
    flex-grow: 1;
    font-size: 14px;
    line-height: 1.4em;
    color: $text-color;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding: 0px 20px;
}
.eventCard_button{
    margin-left: auto;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: -40px;
}