@import '../../../../styles/settings.scss';

.eventList{
    padding-top: 100px;
    background-color: $neutral-color;

}
.eventList_list{
    padding-top: 50px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

}
.eventList_loader{
    padding-top: 180px;

}
.eventList_empty{
    font-size: 18px;
    color: $text-color;
}
.eventList_blog{

    &___selected{
        border: 3px solid $blue-color-600;
    }
}