
.dateTime {
    display: flex;
}

.dateTime_date {
    color: inherit;
    font-weight: 600;
    font-size: inherit;
    padding-left: .5em;
    border-left: 1px solid;
    border-color: inherit;
    &___time {
        border-left: none;
        border-right: 1px solid;
        padding-right: .5em;
        padding-left: 0;
        position: relative;

    }
}