@import '../../../styles/settings.scss';
.rating {
    box-sizing: content-box;
    min-width: 20px;
    height: 25px;
    border-radius: 20px;
    padding: 5px 10px;
    text-align: center;
    
    color: $primary-color;
    font-weight: 500;

    display: flex;
    align-items: center;
    font-weight: 600;

    &::before {
        content: '';
        display: block;
        height: 100%;
        width: 23px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 5px;

    }
}

.rating___likes {
    background-color: $red-color-600;

    &::before {

        background-image: url('../../../assets/icons/like.svg');
    }

}

.rating___repost {
    background-color: $green-color-600;

    &::before {

        background-image: url('../../../assets/icons/share.svg');

    }
}
