.error{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.error_inner{
    display: flex;
    justify-content: center;

}
.error_goHome{
    margin-top: 40px;
    font-size: 20px;
}