@import '../../../styles/settings.scss';

.blogCard {
    width: 100%;
}

.blogCard_inner {
    background: linear-gradient(to bottom, rgba($primary-color, .4), $primary-color);
    padding-top: 10px;
    padding-bottom: 10px;
}

.blogCard_content {
    display: flex;
    flex-direction: column;
}

.blogCard_leftPart {
    display: flex;
    justify-content: center;
    padding-right: 10px;
    padding-left: 10px;
}

.blogCard_imageBox {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    max-height: 400px;

}

.blogCard_image {
    object-fit: cover;
}

.blogCard_blogScore {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: $primary-color;
    padding: 7px 10px;
    border-radius: 30px;
    display: flex;
    gap: 10px;
}

.blogCard_rightPart {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    &>* {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.blogCard_dateTime {
    justify-content: center;
    margin-bottom: 5px;
    font-size: 17px;
}


.blogCard_buttons {
    margin-top: 15px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
}

.blogCard_button {
    display: block;
    width: 100%;
}

.blogCard_text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
}


@media (min-width: 479.98px) {
    .blogCard {
        padding-bottom: 20px;
        padding-top: 30px;
        overflow: hidden;

    }

    .blogCard_inner {
        background: linear-gradient(to right, rgba($primary-color, .4), $primary-color);
        padding: 20px;
        border-radius: 30px;

    }

    .blogCard_content {
        flex-direction: row;

    }

    .blogCard_leftPart {
        margin-top: -40px;
        padding: 0;
    }

    .blogCard_imageBox {
        height: 100%;
        max-height: 250px;
        width: 200px;
        border-radius: 20px;
        box-shadow: -2px 3px 9px -2px rgba($shadow-color, .5);
    }

    .blogCard_image {
        height: 100%;
        width: 100%;
    }

    .blogCard_rightPart {
        margin-top: 0px;

        &>* {
            padding-right: 0px;
            padding-left: 15px;
        }
    }

    .blogCard_dateTime {
        font-size: 15px;
        justify-content: flex-start;
    }

    .blogCard_buttons {
        justify-content: flex-end;
        gap: 15px;
        margin-bottom: -40px;
        flex-direction: row;
        padding: 0px;
    }

    .blogCard_button {
        width: auto;
    }

    .blogCard_text {
        -webkit-line-clamp: 8;

    }
}

@media (min-width: 575.98px) {
    .blogCard_leftPart {
        margin-top: -40px;
    }

    .blogCard_imageBox {
        max-height: 300px;
        width: 250px;
        border-radius: 25px;
    }

    .blogCard_rightPart {
        &>* {
            padding-left: 20px;
        }
    }

    .blogCard_dateTime {
        justify-content: right;
        font-size: 16px;
    }

    .blogCard_text {
        text-align: justify;
    }
}

@media (min-width: 767.98px) {

    .blogCard_imageBox {
        max-height: 350px;
        width: 250px;
        border-radius: 30px;
    }

    .blogCard_rightPart {
        &>* {
            padding-left: 30px;
        }

    }

    .blogCard_dateTime {
        margin-bottom: 10px;
    }

    .blogCard_buttons {
        gap: 20px;
    }

    .blogCard_text {
        -webkit-line-clamp: 10;
        text-align: justify;
    }
}