.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.card_img {
    object-fit: contain;
    max-width: 120px;
    height: 80px;
}
.card_text{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.card_title {
    font-weight: 600;
    padding-top: 10px;

}