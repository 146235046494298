@import '../../styles/settings.scss';

.modal {
    z-index: 9999;
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    bottom: 20px;
    background-color: transparent;
    
}

.modal_inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.modal_close {
    background-color: rgba($shadow-color, .4);
    border: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}