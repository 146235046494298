.bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bio_textBlock {
  padding-top: 3em;

  & p {
    text-align: justify;
  }
}

.bio_achievementsTitle {
  padding-top: 1.2em;
  padding-bottom: 1.1em;
  font-size: 18px;
}

.bio_achievementsList {
  gap: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.bio_achievementsCard {
  width: 100%;

  & * {
    font-size: 15px;
  }

  & img {
    max-width: 150px;
    height: 80px;
  }
}

@media (min-width: 479.98px) {
  .bio_achievementsCard {
    & * {
      font-size: 14px;
    }

    & img {
      max-width: 120px;
      height: 90px;
    }
  }
}

@media (min-width: 575.98px) {

  .bio_achievementsList {
    & * {
      font-size: 15px;
    }
  }
  .bio_achievementsTitle {
    font-size: 20px;
  }

}

@media (min-width: 767.98px) {

  .bio_achievementsCard {

    & * {
      font-size: 15px;
    }

    & img {
      height: 80px;
    }
  }

  .bio_achievementsList {
    gap: 20px;
    flex-wrap: nowrap;
  }
}


@media (min-width: 991.98px) {
  .bio_achievementsCard {
    & * {
      font-size: 16px;
    }
  }
  .bio_achievementsTitle {
    font-size: 24px;
  }
}
