.shortBlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

}

.shortBlog_inner {
  padding-top: 3em;
  display: flex;
  gap: 10px;

  box-sizing: content-box;
  flex-direction: column;
  min-height: 500px;
}

.shortBlog_blog {
  width: 100%;

}

@media (min-width: 479.98px) {
  .shortBlog_blog {
    width: 100%;
  
    &:nth-child(3) {
      display: none;
    }
  }
}

@media (min-width: 575.98px) {
  .shortBlog_inner {
    flex-direction: row;
    min-height: 260px;
    height: 260px;
  }
}


@media (min-width: 991.98px) {
  .shortBlog_inner {
    gap: 15px;
    height: 400px;
  }

  .shortBlog_blog {
    &:nth-child(3) {
      display: block;
    }
  }
}