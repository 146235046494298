@import '../../../../styles/settings.scss';


.header {
    position: fixed;
    animation: animate-down .3s ease-in-out;
    box-shadow: 0px 9px 7px -3px rgba($shadow-color, .1);
    background-color: $primary-color;
    z-index: 1111;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 3px;
}

.header_inner {
    height: 70px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
}

.header_nav {
    flex-grow: 1;
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    background-color: $primary-color;
    gap: 40px;
    transition: transform .3s ease-in-out;
    transform: translateX(120%);

    &___active {
        transform: translateX(0px);
    }
}

.header_linksList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    overflow: hidden;
}

.header_logo {
    height: 40px;
}

.header_link {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    color: $title-color;
    padding: 5px 0;
    transition: color .3s ease-in-out;

    &:hover {
        color: $blue-color-600;
    }

    &___active {
        color: $blue-color-600;
    }
}


.header_contactsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}


.header_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.header_modalBtn {
    position: relative;
}



.header_burgerBtn {
    position: relative;
    display: flex;
    padding: 0;
    height: 27px;
    width: 28px;
    flex-direction: column;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    & span {
        transition: all .2s;
        background-color: $title-color;
        height: 4px;
        width: 100%;
        margin-top: 5px;
        border-radius: 4px;

        &:first-child {
            margin-top: 0;
        }
    }

    &___close {
        & span {
            position: absolute;
            margin: 0;
            padding: 0;
            top: 50%;
            right: 0;

            &:nth-child(odd) {
                transform: translateY(-50%) rotate(45deg);
            }

            &:nth-child(even) {
                transform: translateY(-50%) rotate(-45deg);
            }
        }
    }
}


.header_scrollProgress {
    width: 100%;
    transform-origin: 0%;
    height: 3px;
    background-color: $blue-color-600;
    position: relative;
    bottom: -3px;
}


@media (min-width: 767.98px) {
    .header_nav {
        transform: none;
        position: static;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0;
        gap: 20px;
        transition: none;
    }

    .header_linksList {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        column-gap: 20px;
        row-gap: 5px;
        flex-wrap: wrap;
    }

    .header_link {
        font-size: 16px;
    }

    .header_modalBtn {

    }

    .header_contactsList {
        flex-direction: row;
        gap: 20px;

        & a {
            font-size: 0px;

            &::after,
            &::before {
                height: 25px;
                width: 25px;
            }
        }
    }

    .header_burgerBtn {
        display: none;
    }
}



@media (min-width: 1079.98px) {

    .header_contactsList {
        position: static;
        padding: 0;
        transform: none;
        height: auto;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: row;

        & a {
            font-size: 16px;

            &::after,
            &::before {
                height: 20px;
                width: 20px;
            }
        }
    }
}


@keyframes animate-down {
    0% {
        transform: translateY(-100%);

    }

    100% {
        transform: translateY(0);

    }
}