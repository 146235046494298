.text{

}
.text_block{
    padding-top: 10px;
    &:first-child{
        padding-top: 0;
    }
}
.text_title {
   // padding-left: 10px;
}

.text_text {
    padding-bottom: 5px;
    &:last-child{
        padding-bottom: 0px;

    }
}