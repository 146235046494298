@import '../../../../styles/settings.scss';


.messageForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}


.messageForm_inputBox {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 10px;
    align-items: center;
}

.messageForm_input {
    padding-top: 13px !important;
    font-size: 16px;
}

.messageForm_text {
    &___error {
        & .messageForm_textTitle {
            color: $red-color-600;
        }

        & .messageForm_textArea {
            border: 2px solid $red-color-600 !important;
        }
    }
}

.messageForm_textTitle {
    padding-bottom: 5px;
    padding-top: 5px;
    color: $text-color;
    font-size: 16px;
    font-weight: 600 !important;
    padding-bottom: 10px;
}

.messageForm_textError {
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 1em;
    margin-top: 3px;
}

.messageForm_textArea {
    display: block;
    padding: 7px 9px;
    background-color: transparent;
    outline: none;
    border: 2px solid $text-color;
    border-radius: 4px;
    width: 100%;
    resize: vertical;
    max-height: 200px;
    min-height: 70px;

    &:focus {
        border: 2px solid $blue-color-600;
    }
}

.messageForm_buttonBox {
    display: flex;
    gap: 15px;
    row-gap: 8px;
    justify-content: flex-end;
    margin-top: 10px;
    flex-wrap: wrap;

}

.messageForm_button {
    padding: 2px;
    border-radius: 10px;
    font-size: 18px;
    background-color: transparent;
    color: $blue-color-600;

    &___send {
        //color: $green-color-600;
    }
}

.messageForm_fileInput {
    font-size: 17px;
    font-weight: 600;
    margin-top: 5px;
}


.messageForm_phoneInput {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid $text-color;

}
.messageForm_loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($neutral-color, .5);
}
@media (min-width: 575.98px) {

    .messageForm_form {
        gap: 15px;
    }

    .messageForm_inputBox {
        row-gap: 15px;
    }
}

@media (min-width: 767.98px) {

    .messageForm_inputBox {
        flex-wrap: nowrap;
    }
}